import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '340'
  },
  xl: {
    height: '450'
  }
}

const ProgramsVR = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "vr-programs.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      maxText={520}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
        <HomeSectionTitle tag='h2'>
          Besoin de vous projeter dans votre futur logement&nbsp;?
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Réalisez une visite virtuelle personnalisée selon vos goûts et vos critères de recherche.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Profitez aussi de notre partenariat avec un grand nom de l’ameublement : Maisons du Monde.
        </HomeSectionDescription>
    </ImageSection>
  )
}

export default ProgramsVR
