import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { map, nth, isEqual, get, size } from 'lodash'
import styled, { css } from 'styled-components'

import media from '../../../utils/media'

const Content = styled.div`
  flex: 1;
  position: relative;
  height: auto;

  ${media.lessThan('lg')`
    margin-bottom: 379px;
  `}

  ${media.lessThan('sm')`
    margin-bottom: 286px;
  `}
`

const StyledImage = styled(GatsbyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${({ isDisplayed }) => !isDisplayed && css`opacity: 0;`}
  transition: all 1s ease-in-out;

  ${media.lessThan('lg')`
    height: 379px;
  `}

  ${media.lessThan('sm')`
    height: 286px;
  `}
`

const DotsContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: 0;
  height: 100%;
  padding-bottom: 32px;

  ${media.lessThan('lg')`
    height: 379px;
  `}

  ${media.lessThan('sm')`
    height: 286px;
  `}
`

const ButtonContainer = styled(DotsContainer)`
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 0;
  font-size:10px;
`

const Button = styled(FontAwesomeIcon)`
  cursor: pointer;
  z-index: 9999;

  ${media.lessThan('xl')`
    font-size: 40px;
  `}
`

const Dot = styled.div`
  z-index: 9999;
  height: 12px;
  width: 12px;
  opacity: ${({ isCurrent }) => isCurrent ? 1 : 0.5};
  background-color: ${({ theme }) => get(theme, 'white')};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin: 0 15px;
`

const PageCarousel = ({ imgs }) => {
  const images = useRef(map(imgs, image => getImage(image)))
  const [step, updateStep] = useState(0)
  const [isHover, updateIsHover] = useState(false)

  const increment = () => updateStep((step + 1) % size(imgs))
  const decrement = () => {
    const newStep = (step - 1) % size(imgs)

    if (newStep < 0) {
      return updateStep(size(imgs) - 1)
    }

    return updateStep(newStep)
  }

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (!isHover) {
        return updateStep((step + 1) % size(imgs))
      }
    }, 5000)

    return () => {
      window.clearInterval(timer)
    }
  }, [images, step, isHover])

  const handlers = useSwipeable({
    onSwipedLeft: decrement,
    onSwipedRight: increment,
    preventDefaultTouchmoveEvent: true
  })

  return (
    <Content
      onMouseEnter={() => updateIsHover(true)}
      onMouseLeave={() => updateIsHover(false)}>
      {map(images.current, (_, idx) => (
        <StyledImage
          alt={`Image carousel folhomee #${idx}`}
          key={`image-${idx}`}
          image={nth(images.current, idx)}
          isDisplayed={isEqual(step, idx)}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)} />
      ))}
      <ButtonContainer
        {...handlers}
        onMouseEnter={() => updateIsHover(true)}
        onMouseLeave={() => updateIsHover(false)}>
        <Button
          size='5x'
          icon='chevron-left'
          color='white'
          onClick={decrement}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)} />
        <Button
          size='5x'
          icon='chevron-right'
          color='white'
          onClick={increment}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)} />
      </ButtonContainer>
      <DotsContainer
        {...handlers}
        onMouseEnter={() => updateIsHover(true)}
        onMouseLeave={() => updateIsHover(false)}>
        {map(images.current, (_, idx) => (
          <Dot
            key={`dot-${idx}`}
            isCurrent={isEqual(idx, step)}
            onClick={() => updateStep(idx)}
            onMouseEnter={() => updateIsHover(true)}
            onMouseLeave={() => updateIsHover(false)} />
        ))}
      </DotsContainer>
    </Content>
  )
}

PageCarousel.propTypes = {
  imgs: PropTypes.array.isRequired
}

export default PageCarousel
