import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import ProgramsHero from '../../molecules/ProgramsHero'

const Background = styled.div`
  background-color: ${({ theme }) => get(theme, 'paleBlue')}
`

const ProgramsTopSection = () => (
  <Background>
    <ProgramsHero />
  </Background>
)

export default ProgramsTopSection
