import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import CitiesForm from '../../molecules/CitiesForm'

const FormContainer = styled.div`
  padding-top: 150px;
  padding-left: 150px;
  padding-bottom: 50px;

  ${media.lessThan('xl')`
    padding-left: 20px;
    padding-right: 20px;
  `}
`

const ProgramsHero = () => (
  <FormContainer>
    <CitiesForm />
  </FormContainer>
)

export default ProgramsHero
