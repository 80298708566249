import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import StyledList from '../../atoms/StyledList'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '682'
  },
  xl: {
    height: '617'
  }
}

const ProgramsExplanation = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "explanation-programs.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      reverse={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
        <HomeSectionTitle tag='h2'>
          Qu’est-ce qu’un programme immobilier neuf&nbsp;?
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Comme son nom l’indique, un programme immobilier neuf est une construction neuve.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Divers aspects de ces biens sont travaillés par les promoteurs avant de les proposer à la vente. Dans les faits, parmi les variables caractérisant un programme immobilier neuf se trouvent&nbsp;:
        </HomeSectionDescription>
        <StyledList>
          <li>
            <strong>le type de logement&nbsp;:</strong> individuel ou collectif&nbsp;;
          </li>
          <li>
            <strong>le nombre de logements</strong> que contiendra le programme neuf&nbsp;;
          </li>
          <li>
            <strong>le thème</strong> de la résidence&nbsp;;
          </li>
          <li>
            <strong>le niveau de prestations</strong> proposé, etc.
          </li>
        </StyledList>
        <HomeSectionDescription>
          Les programmes immobiliers neufs permettent de faire face à la crise du logement, tout en proposant de beaux avantages à leurs acheteurs. Ceux-ci peuvent être des particuliers ou des professionnels. En effet, toute personne peut investir dans un programme immobilier neuf et il est possible d’acheter du neuf sous forme de lot de copropriété.
        </HomeSectionDescription>
    </ImageSection>
  )
}

export default ProgramsExplanation
