import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import ProgramsCarousel from '../../molecules/ProgramsCarousel'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => get(theme, 'veryLightOrange')};

  ${media.lessThan('lg')`
    flex-direction: column-reverse;
  `}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 56px 24px;
  width: 50%;
  height: auto;

  ${media.greaterThan('sm')`
    padding: 72px 40px;
  `}

  ${media.greaterThan('md')`
    padding: 72px;
  `}

  ${media.lessThan('lg')`
    width: 100%;
  `}

  ${media.greaterThan('xl')`
    height: 400px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  max-width: 100%;

  ${media.greaterThan('xl')`
    max-width: 460px;
  `}
`

const HomeAdvantages = () => (
  <Container>
    <ContentContainer>
      <Content>
        <HomeSectionTitle>
          Les avantages de l’immobilier neuf
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Folhomee vous aide. Pour cela, rien de plus simple !
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Ceux-ci varient en fonction de la typologie de l’acheteur, mais aussi selon le programme immobilier neuf dans lequel on investit. Ils présentent différents avantages fiscaux et non fiscaux: TVA réduite, Prêt à taux zéro, garantie décennale,  économie d’énergie, etc.
        </HomeSectionDescription>
      </Content>
    </ContentContainer>
    <ProgramsCarousel />
  </Container>
)

export default HomeAdvantages
