import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Residencies from '../../molecules/Residencies'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RESIDENCIES = [{
  city: 'île-de-France',
  title: 'Programmes immobiliers Neufs',
  image: 'paris'
}, {
  city: 'Bordeaux',
  title: 'Logements neufs',
  image: 'bordeaux'
}, {
  city: 'Lyon',
  title: 'Programmes immobiliers Neufs',
  image: 'lyon'
}, {
  city: 'Marseille',
  title: 'Constructions résidentielles neuves',
  image: 'marseille'
}]

const DetailsContainer = styled.div`
  display: flex;
  margin: 32px auto;
  justify-content: center;
  gap: 16px;
  max-width: ${({ maxWidth }) => maxWidth};

  ${media.lessThan('md')`
    align-items: center;
    flex-direction: column;
  `}

  ${media.greaterThan('md')`
    flex-wrap: wrap;
    margin: 40px auto;
    width: 85%;
  `}

  ${media.greaterThan('lg')`
    width: 95%;
  `}
`
const SCREEN_SIZE = {
  xs: {
    width: '280px',
    height: '100%'
  },
  sm: {
    width: '400px',
    height: 'auto'
  },
  md: {
    width: '80%',
    height: 'auto'
  }
}

const ProgramsDetails = () => (
  <HomeContainer max={1500}>
    <HomeSectionTitle tag='h3'>
      Trouvez le bien immobilier neuf qui vous correspond parmi 2&nbsp;450 programmes et 46&nbsp;674 appartements neufs dans 1&nbsp;108 villes de France.
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Quels que soient vos critères et votre zone de recherche, dénichez le logement neuf qu’il vous faut.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Par exemple, en recherchant des biens sur notre plateforme, découvrez des :
    </HomeSectionDescription>
    <DetailsContainer>
      <Residencies
        size={SCREEN_SIZE}
        residencies={RESIDENCIES} />
    </DetailsContainer>
    <HomeSectionDescription>
      L’équipe Folhomee vous livre des conseils d’expert, non seulement pour votre recherche, mais aussi pour le financement de votre achat de programme immobilier neuf. Profitez, entre autres, de nos connaissances sur les <span>différents types de prêts immobiliers.</span>
    </HomeSectionDescription>
  </HomeContainer>
)

export default ProgramsDetails
