import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoNew from '../../../assets/new.inline.svg'
import PictoHeart from '../../../assets/heart.inline.svg'
import PictoExpert from '../../../assets/expert.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import PictoBonAchat from '../../../assets/bon-achat.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoNew,
  title: 'Découvrir des biens immobiliers neufs',
  description: 'qui répondent vraiment à vos critères'
}, {
  icon: PictoHeart,
  title: 'Bénéficier d’une aide',
  description: 'en termes d’aménagement d’intérieur et de décoration'
}, {
  icon: PictoExpert,
  title: 'Profiter de conseils d’experts',
  description: 'et d’un accompagnement sur-mesure'
}, {
  icon: PictoBonAchat,
  title: 'Recevoir un bon d’achat',
  description: 'valable pour votre ameublement chez Maisons du Monde'
}]

const StyledTitle = styled(HomeSectionTitle)`
  margin: auto;

  ${media.lessThan('sm')`
    font-size: 22px;
  `}
`

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const ProgramsAfter = () => (
  <HomeContainer max={1500}>
    <StyledTitle tag='h4'>
      Finalement, acheter un bien issu d’un programme immobilier neuf avec Folhomee, c’est :
    </StyledTitle>
    <PictoLineContainer details={DETAILS_LINE}/>
    <BottomText>
      <strong>Les experts Folhomee sont à votre disposition pour vous conseiller dans votre investissement immobilier neuf</strong>
    </BottomText>
    <StyledButtonContainer>
      <LaunchButton
        link='/services/neuf'
        title='Contacter un conseiller neuf Folhomee' />
    </StyledButtonContainer>
  </HomeContainer>
)

export default ProgramsAfter
