import React from 'react'
import styled from 'styled-components'
import { get, map, range, isEqual } from 'lodash'
import media from '../../../utils/media'

import HomeContainer from '../../atoms/HomeContainer'
import ResourcesTable from '../../molecules/ResourcesTable'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ZONES = [{
  title: 'Zone ABis et A',
  lines: ['37 000 €', '51 800 €', '62 900 €', '74 000 €', '85 100 €']
}, {
  title: 'Zone B1',
  lines: ['30 000 €', '42 000 €', '51 000 €', '60 000 €', '69 000 €']
}, {
  title: 'Zone B2',
  lines: ['27 000 €', '37 800 €', '45 900 €', '54 000 €', '62 100 €']
}, {
  title: 'Zone C',
  lines: ['24 000 €', '33 600 €', '40 800 €', '48 000 €', '55 200 €']
}]

const PERSONS = map(range(1, 6), idx => {
  if (isEqual(idx, 1)) {
    return '1 personne'
  }

  return `${idx} personnes`
})

const Container = styled.div`
  background-color: ${({ theme }) => get(theme, 'paleGrey')}; 
  padding: 72px 0;

  ${media.lessThan('sm')`
    padding: 56px 0;
  `}
`

const ResourcesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto;

  ${media.greaterThan('sm')`
    margin: 40px auto;
  `}
`

const StyledDescription = styled(HomeSectionDescription)`
  max-width: 725px;
  margin: 0 auto;
`

const ProgramsDetails = () => (
  <Container>
    <HomeContainer max={1150}>
      <HomeSectionTitle tag='h3'>
        Pour accéder au prêt à taux zéro dans le cadre d’un investissement dans un programme immobilier neuf&nbsp;:
      </HomeSectionTitle>
      <ResourcesContainer>
        <ResourcesTable
          top={13}
          zones={ZONES}
          title='Nb. de personne(s) du foyer fiscal'
          persons={PERSONS}
          subtitle='Plafond de ressources maximal du foyer fiscal pour obtenir un PTZ en 2021'
          marginBottom={25} />
      </ResourcesContainer>
      <StyledDescription>
        Le tableau ci-contre présente un aperçu des caractéristiques à respecter. Par ailleurs, les investisseurs peuvent profiter de dispositifs de défiscalisation dans le cadre d’un investissement dans un programme immobilier neuf. En effet, ce type d’achat permet de bénéficier du dispositif Pinel.
      </StyledDescription>
    </HomeContainer>
  </Container>
)

export default ProgramsDetails
