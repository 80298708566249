import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'

import media from '../../../utils/media'
import ResidencyImage from '../../atoms/ResidencyImage'

const ResidencyContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  width: ${({ size }) => get(size, 'sm.width')};
  height: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 73, 238, 0.15);
  border-radius: 18px;
  
  ${media.lessThan('sm')`
    width: ${({ size }) => get(size, 'xs.width')};
  `}

  ${media.greaterThan('md')`
    flex: 1 1 300px;
    width: ${({ size }) => get(size, 'md.width', 'auto')};
  `}

  ${media.greaterThan('lg')`
    flex: 1;
  `}
`

const ResidencyCard = styled.div`
  border-radius: 18px;
  background-color: #ffffff;
  text-decoration: none;
  height: 100%;
  width: 100%;
`

const ResidencyTitleContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ResidencyCity = styled.p`
  font-family: 'Source Sans Pro';
  ${({ city }) => city ? 'height: auto;' : ''}
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
`

const ResidencyTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 8px 0;
  ${({ city, html }) => !city && !html ? 'text-align: center;' : ''}
  color: ${({ theme }) => get(theme, 'blue', '#fff')};

  ${media.lessThan('sm')`
    font-size: 16px;
  `}
`

const ResidencyText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 1.4;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};

  & > strong {
    font-weight: 600;
  }

  & > span a {
    color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
    text-decoration: none;
  }

  ${media.lessThan('sm')`
    font-size: 15px;
  `}
`

const Residencies = ({ residencies, size }) => map(residencies, ({ title, image, city, html }) => (
  <ResidencyContainer key={`residency-${city}-${title}`} size={size}>
    <ResidencyCard key={`residency-${city}-${title}`}>
      <ResidencyImage name={image} />
      <ResidencyTitleContainer>
        <ResidencyCity city={city}>{city}</ResidencyCity>
        <ResidencyTitle city={city} html={html}>{title}</ResidencyTitle>
        <ResidencyText dangerouslySetInnerHTML={{ __html: html }} />
      </ResidencyTitleContainer>
    </ResidencyCard>
  </ResidencyContainer>
))

Residencies.propTypes = {
  size: PropTypes.object,
  residencies: PropTypes.array.isRequired
}

Residencies.defaultProps = {
  size: {
    xs: {
      width: '90%'
    },
    sm: {
      width: '80%'
    },
    md: {
      width: 'auto'
    }
  }
}

export default Residencies
