import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import HomeNew from '../components/organisms/HomeNew'
import ProgramsVR from '../components/organisms/ProgramsVR'
import ProgramsPTZ from '../components/organisms/ProgramsPTZ'
import SiteSection from '../components/atoms/SiteSection/'
import ProgramsAfter from '../components/organisms/ProgramsAfter'
import ProgramsIntro from '../components/organisms/ProgramsIntro'
import ProgramsCities from '../components/organisms/ProgramsCities'
import ProgramsFiscal from '../components/organisms/ProgramsFiscal'
import ProgramsProfits from '../components/organisms/ProgramsProfits'
import ProgramsDetails from '../components/organisms/ProgramsDetails'
import ProgramsFolhomee from '../components/organisms/ProgramsFolhomee'
import ProgramsAdvantages from '../components/organisms/ProgramsAdvantages'
import ProgramsTopSection from '../components/organisms/ProgramsTopSection'
import ProgramsExplanation from '../components/organisms/ProgramsExplanation'

const TITLE = 'Folhomee : 2 500 programmes immobiliers neufs en France'
const DESCRIPTION = 'Parcourez les 46 500 biens immobiliers neufs que propose Folhomee et faites-vous accompagner par nos experts pour votre achat.'

const ProgrammesImmobiliersNeufs = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      location={location}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <ProgramsTopSection />
    <section id='tout-savoir'>
      <ProgramsIntro />
    </section>
    <SiteSection id='liste-programmes'>
      <HomeNew />
    </SiteSection>
    <SiteSection id='neuf-explication'>
      <ProgramsExplanation />
    </SiteSection>
    <SiteSection id='neuf-details'>
      <ProgramsDetails />
    </SiteSection>
    <SiteSection id='neuf-advantages'>
      <ProgramsAdvantages />
    </SiteSection>
    <SiteSection id='neuf-fiscalité'>
      <ProgramsFiscal />
    </SiteSection>
    <SiteSection id='programmes-ptz'>
      <ProgramsPTZ />
    </SiteSection>
    <SiteSection id='programmes-profits'>
      <ProgramsProfits />
    </SiteSection>
    <SiteSection id='programmes-folhomee'>
      <ProgramsFolhomee />
    </SiteSection>
    <SiteSection id='programmes-villes'>
      <ProgramsCities />
    </SiteSection>
    <SiteSection id='programmes-vr'>
      <ProgramsVR />
    </SiteSection>
    <SiteSection id='programmes-après'>
      <ProgramsAfter />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

ProgrammesImmobiliersNeufs.propTypes = {
  location: PropTypes.object.isRequired
}

export default ProgrammesImmobiliersNeufs
