import React from 'react'

import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import PictoReducedTVA from '../../../assets/reduced-tva.inline.svg'
import PictoPTZ from '../../../assets/ptz.inline.svg'

const DETAILS_LINE = [{
  icon: PictoReducedTVA,
  title: 'TVA réduite',
  description: 'L’achat d’un bien immobilier neuf est initialement soumis à une TVA de 20%, mais il est possible d’obtenir un taux de TVA réduit à 5,5%. Pour cela, il faut répondre à 2 critères : effectuer un achat dans une <strong>zone en développement éligible</strong> et que les revenus de l’acheteur ne dépassent pas un certain montant.'
}, {
  icon: PictoPTZ,
  title: 'PTZ • Prêt à Taux Zéro',
  description: 'Si l’achat concerne une résidence principale neuve, il est possible de profiter d’un prêt à taux zéro. C’est un avantage non négligeable, qui permet de <strong>réaliser de belles économies</strong> et de gonfler votre budget. Pour cela, il ne faut pas dépasser un certain niveau de revenu selon le ménage.'
}]


const ProgramsDetails = () => (
  <HomeContainer max={1200}>
    <HomeSectionTitle tag='h3'>
      Quels sont les avantages fiscaux liés à un programme immobilier neuf&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Pour tout achat d’un bien immobilier neuf, les frais de notaire sont réduits. La réduction de frais de notaire figure parmi les principaux avantages des programmes immobiliers neufs.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Il apparaît, en effet, que les frais de notaire dans l’ancien sont de 8%, contre <strong>2,5% pour le neuf.</strong>
      <br />
      Il existe d’autres avantages fiscaux attenants à l’achat d’un programme immobilier neuf pour résidence principale, les voici&nbsp;:
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
  </HomeContainer>
)

export default ProgramsDetails
