import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '492'
  }
}

const ProgramsFolhomee = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "folhomee-programs.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
        <HomeSectionTitle tag='h2'>
          Trouver un logement neuf avec Folhomee
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Folhomee, expert dans l’immobilier neuf, travaille aujourd’hui avec plus de 200 promoteurs.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Notre équipe vous guide dans vos recherches immobilières et ne vous présente que des biens qui comblent toutes vos attentes. En effet, chez Folhomee, il est possible de sélectionner plus de 20 critères de recherche. Cela vous permet d’accéder à des programmes immobiliers neufs, ou d’autres biens, qui vous correspondent vraiment.
        </HomeSectionDescription>
        <br />
        <HomeSectionDescription>
          Avec Folhomee, profitez d’un accompagnement sur-mesure. Cet accompagnement s’étend de la recherche de programmes immobiliers neufs à la signature chez le notaire.
        </HomeSectionDescription>
    </ImageSection>
  )
}

export default ProgramsFolhomee
