import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import Centered from '../../atoms/Centered'
import Residencies from '../../molecules/Residencies'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RESIDENCIES = [{
  title: 'Appartements neufs',
  image: 'apartment'
}, {
  title: 'Maisons neuves',
  image: 'house'
}, {
  title: 'Résidences de service',
  image: 'old'
}]

const SCREEN_SIZE = {
  xs: {
    width: '280px',
    height: '100%'
  },
  sm: {
    width: '400px',
    height: 'auto'
  },
  md: {
    width: '80%',
    height: '120px'
  }
}

const DetailsContainer = styled.div`
  display: flex;
  margin: 32px auto;
  justify-content: center;
  gap: 16px;
  width: 80%;
  max-width: ${({ maxWidth }) => maxWidth};
  
  ${media.lessThan('md')`
    align-items: center;
    flex-direction: column;
  `}

  ${media.greaterThan('md')`
    margin: 40px auto;
    width: 100%;
  `}

  ${media.greaterThan('lg')`
    width: 95%;
  `}
`

const LastContainer = styled(Centered)`
  background: #ffffff;
  display: flex;
  min-width: 250px;
  
  
  ${media.lessThan('md')`
    padding: 15px 0px;
    width: auto;
    flex-direction: column;
  `}
`

const LastContainerBordered = styled.div`
  position: relative;
  margin-left: 8px;
  margin-right: 16px;
  border-left: 4px solid ${({ theme }) => get(theme, 'lightOrange', '#fff')};

  ${media.greaterThan('md')`
    height: 90%;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 45%;
      left: -33px;
      border: 15px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'lightOrange', '#fff')};
      transform: rotate(-90deg);
    }

    &:after {
      top: 45%;
      left: -27px;
      border: 15px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'white', '#fff')};
    }
  `}
  
  ${media.lessThan('md')`
    width: 59%;
    margin: 0px auto;
    margin-bottom: 10px;
    border-left: none;
    border-top: 4px solid ${({ theme }) => get(theme, 'lightOrange', '#fff')};

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -31px;
      left: 45%;
      border: 15px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'lightOrange', '#fff')};
    }

    &:after {
      top: -25px;
      left: 45%;
      border: 15px solid transparent;
      border-bottom-color: ${({ theme }) => get(theme, 'white', '#fff')};
    }
  `}
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  max-width: auto;
    ${media.greaterThan('lg')`
      max-width: 320px;
    `}
`

const ProgramsDetails = () => (
  <HomeContainer max={1200}>
    <HomeSectionTitle tag='h3'>
      Pour entrer un peu plus dans les détails&nbsp;:
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Il existe différents types de programmes immobiliers neufs. Voici les plus répandus&nbsp;:
    </HomeSectionSubtitle>
    <DetailsContainer>
      <Residencies
        size={SCREEN_SIZE}
        residencies={RESIDENCIES} />
      <LastContainer>
        <LastContainerBordered />
        <StyledHomeSectionDescription>
          Les résidences de services peuvent être des <strong>résidences étudiantes</strong> (tout un immeuble dédié au logement étudiant, un programme neuf qui inclut des services divers) ou encore des <strong>résidences seniors</strong> (une alternative à l’EHPAD qui propose une totale autonomie à ses habitants, avec des services variés).
        </StyledHomeSectionDescription>
      </LastContainer>
    </DetailsContainer>
    <HomeSectionDescription>
      À noter que les programmes immobiliers neufs, selon leur typologie, peuvent être une résidence principale, une résidence locative ou encore une résidence de service.
    </HomeSectionDescription>
    <ButtonContainer>
      <LaunchButton title='Trouvez le logement neuf qu’il vous faut avec Folhomee' />
    </ButtonContainer>
  </HomeContainer>
)

export default ProgramsDetails
