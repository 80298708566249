import React from 'react'
import styled from 'styled-components'
import media from '../../../utils/media'

import PictoKeys from '../../../assets/keys.inline.svg'
import PictoPiggy from '../../../assets/piggy.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoGarantieDecennale from '../../../assets/garantie-decennale.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoKeys,
  title: 'Profiter d’un logement clé en main',
  description: 'dès sa livraison, aménagé au goût de l’acheteur, sans aucun travaux à prévoir.'
}, {
  icon: PictoGarantieDecennale,
  title: 'Garantie décennale',
  description: 'qui assure à l’acheteur de ne pas avoir à réaliser de travaux durant 10 ans dans la copropriété.'
}, {
  icon: PictoPiggy,
  title: 'Économies d’énergie non négligeables',
  description: 'Réduit ainsi les charges mensuelles car les programmes immobiliers neufs respectent des normes environnementales très strictes (<span>logements BBC RT 2012</span> et <span>RT 2020</span> notamment).'
}]

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
`

const StyledHomeContainer = styled(HomeContainer)`
  ${media.greaterThan('md')`
    padding: 0 72px;
  `}
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const ProgramsProfits = () => (
  <StyledHomeContainer max={1030}>
    <HomeSectionTitle tag='h4'>
      Les autres bénéfices de l’achat d’un bien immobilier neuf
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Outre les avantages fiscaux, d’autres bénéfices sont liés à l’achat d’un programme immobilier neuf.
    </HomeSectionSubtitle>
    <PictoLineContainer details={DETAILS_LINE}/>
    <BottomText>
      <strong>Folhomee vous propose un accompagnement sur-mesure pour votre achat immobilier en programme neuf</strong>
    </BottomText>
    <StyledButtonContainer>
      <LaunchButton title='Parcourir les programmes neufs' />
    </StyledButtonContainer>
  </StyledHomeContainer>
)

export default ProgramsProfits
