import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { get, find, isEqual } from 'lodash'

const getImage = (data, image) => {
  const img = find(get(data, 'backgrounds.nodes'),
    ({ name }) => isEqual(name, image))

  return img
}

const ResidencyImage = ({ name, ...props }) => {
  const data = useStaticQuery(graphql`{
    backgrounds: allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "residencies"}}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  const image = getImage(data, name)

  return (
    <GatsbyImage
      alt={`${name}-residencies-image`}
      image={get(image, 'childImageSharp.gatsbyImageData')}
      {...props} />
  )
}

ResidencyImage.propTypes = {
  name: PropTypes.string.isRequired
}

const StyledImage = styled(ResidencyImage)`
  transition: width 0.25s ease-in-out;
  width: 100%;
  height: 150px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
`

export default StyledImage
