import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import PageCarousel from '../../molecules/PageCarousel'

const ProgramsCarousel = () => {
  const { first, second, third, fourth } = useStaticQuery(graphql`{
    first: file(relativePath: {eq: "programs-carousel/1.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    second: file(relativePath: {eq: "programs-carousel/2.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    third: file(relativePath: {eq: "programs-carousel/3.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    fourth: file(relativePath: {eq: "programs-carousel/4.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <PageCarousel imgs={[first, second, third, fourth]} />
  )
}

export default ProgramsCarousel
