import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '398px'
  }
}

const ProgramsIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "intro-programs.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='programme immobilier neuf'
      fixed={true}
      size={size}
      imageData={placeholderImage}
      backgroundColor='white'>
        <HomeSectionTitle>
          Programme immobilier neuf, tout ce qu’il faut savoir
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Les programmes immobiliers neufs sont des biens immobiliers qui donnent accès à de nombreux avantages. Ainsi, ils séduisent de plus en plus d’acheteurs et d’investisseurs.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Pouvant être acheté en tant que résidence principale ou en tant qu’investissement locatif, le programme immobilier neuf est une des expertises de Folhomee. Que faut-il savoir sur ces biens immobiliers&nbsp;? Comment trouver le programme immobilier neuf qui correspond véritablement à vos attentes&nbsp;?
        </HomeSectionDescription>
    </ImageSection>
  )
}

export default ProgramsIntro
